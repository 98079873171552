import React from 'react';
import Layout from '../components/Layout';
import { SEO } from '../components/seo';

const rowPadding = {
  padding: "100px 0"
}

export default function Faq() {
  return (
    <Layout>
      <section id='our-team' style={{marginTop: '74px'}}>
        <div className='container'>
          <div className='row text-center' style={ rowPadding }>
            <h1 className=''>Frequently Asked Questions</h1>
            <p>Feel free to go through our FAQs section before contacting us</p>    
          </div>
        </div>

        <div className='w-100 bg-white'>
          <div className='container'>
            <div className="accordion text-start" id="accordionExample" style={rowPadding}>

              <div className="accordion-item border-start-0 border-end-0 border-top-0">
                <h2 className="accordion-header" id="headingOne">
                  <button className="accordion-button p-4 collapse" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    How much is the cost to make my website?
                  </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>The cost of a website can vary depending on a number of factors that are involved i.e., the intricacies of the design and the content of the website. Though, our website projects generally start from the $2,000 range, it can increase depending on your unique needs.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingTwo">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    How long will it take to create my website?
                  </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>The time it takes to produce a quality product depends on the size, complexity and quality of your existing content. A basic website typically takes around 4 weeks from beginning to end, but more extensive builds make take longer up to 2 months.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingThree">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Can you design my logo?
                  </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>Yes, we can. We provide a full brand identity design service.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingFour">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    Will my website work on all devices? (Mobile, desktop, tablet)
                  </button>
                </h2>
                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>Yes, it will. Every website we design is built to work across range of devices from mobile phones to desktop computers.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingFive">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFour">
                    Do you do ecommerce / online store website?
                  </button>
                </h2>
                <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>Yes, we do. This is one of our specialties. Contact our experienced eCommerce web designers to discuss your requirements.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingSix">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseFour">
                    Can you help me with social media marketing?
                  </button>
                </h2>
                <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>Absolutely. As a part of your project, we can include social media and marketing services.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingSeven">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseFour">
                    Do I need my own domain?
                  </button>
                </h2>
                <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>Yes. You can either purchase a domain name yourself from a provider or we can secure one for you to include with your price package.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingEight">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseFour">
                    I already own a domain name can I use it?
                  </button>
                </h2>
                <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>Yes, you are welcome to use your existing domain! You have 2 options to use your domain:</p>
                      <strong>Option 1</strong> - <p>You can keep your domain name and your existing domain registrar, and simply point the site built with our site builder to your preferred domain name.</p>
                      <strong>Option 2</strong> - <p>Transfer your domain to us for small fee.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingNine">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseFour">
                    What is a domain name?
                  </button>
                </h2>
                <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>A domain name is your internet address, which enables clients to find your website. Typically, this would be something like businessname.com or in some cases businessname.co.nz for most NZ based clients.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingTen">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseFour">
                    Do you charge monthly web hosting?
                  </button>
                </h2>
                <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>Yes. We charge a small monthly fee for your web hosting and maintenance of your website. This will ensure your website runs smoothly.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingEleven">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseFour">
                    What is web hosting?
                  </button>
                </h2>
                <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>Web hosting is a service that allows your website to be viewed on the internet. It’s basically a computer housed in a data center, which runs your website and email.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingTwelve">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseFour">
                    Do you write the content for my website?
                  </button>
                </h2>
                <div id="collapseTwelve" className="accordion-collapse collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>We don’t write content for website as standard, however, if you need help, we can provide copywriting services as an addition to select projects.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingThirteen">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseFour">
                    Is my website content safely backed up?
                  </button>
                </h2>
                <div id="collapseThirteen" className="accordion-collapse collapse" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>Yes, GoogolWeb does a daily, weekly and monthly backup of your website content. We take extra precautions to make sure that your website is secure and your data is protected.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingFourteen">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFour">
                    Is my website SEO friendly?
                  </button>
                </h2>
                <div id="collapseFourteen" className="accordion-collapse collapse" aria-labelledby="headingFourteen" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>Yes, your website will be found on all search engines, including Google. GoogolWeb automatically provides you a search engine friendly website.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingFifteen">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFour">
                    Do you offer professional email accounts?
                  </button>
                </h2>
                <div id="collapseFifteen" className="accordion-collapse collapse" aria-labelledby="headingFifteen" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>Yes. We can include professional email in our price package. We are the one that will setup and host your email.</p>
                  </div>
                </div>
              </div>

              <div className="accordion-item border-start-0 border-end-0">
                <h2 className="accordion-header" id="headingSixteen">
                  <button className="accordion-button collapsed p-4" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseFour">
                    This page didn’t answer my question, now what?
                  </button>
                </h2>
                <div id="collapseSixteen" className="accordion-collapse collapse" aria-labelledby="headingSixteen" data-bs-parent="#accordionExample">
                  <div className="accordion-body p-4">
                    <p>Try browsing our website to check out our services and price packages. Feel free to send us an email if you have specific questions and our experienced team will be in touch to assist you.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const Head = () => (
  <SEO title="Frequently Asked Questions" />
)